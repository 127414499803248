body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.parent-link {
  width: 15px;
  margin-top: 40px;
}

.join-icon {
  width: 25px;
  height: 25px;
}

.join-type {
  width: 70px;
  height: auto;
  z-index: 1;
  position: absolute;
  left: 40px;
  cursor: pointer;
}

.first-join {
  top: 5px;
}

.middle-join {
  top: 25px;
}

.last-join {
  bottom: 5px;
}

.join-setting-dialog {
  width: 70px;
  margin-bottom: 7.5px;
}

.join-link {
  width: 150px;
  margin-block: 0;
  position: relative;
}

.warning-icon {
  width: 25px;
  margin-left: 0.3rem;
}
